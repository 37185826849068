.vert-head {
    display: table-cell !important;
    vertical-align: middle !important;
  }
  
  .class_results {
    color: #000 !important;
  }
  
  .chosen-container-single .chosen-single {
    height: 35px !important;
    background: none !important;
  }
  
  
  .container-fluid {
    overflow-x: scroll !important;
  }
  
  .lrn-assess .lrn-dialog .lrn-dialog-body {
    margin: 5px 0 !important;
  }
  
  .lrn .lrn-author-ui .lrn-list-view-heading-wrapper a[type="button"] {
    background: #fff;
    position: relative;
  }
  
  .lrn .lrn-author-ui .lrn-list-view-heading-wrapper a[type="button"]::before {
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: block;
    position: absolute;
  }
  
  .lrn .lrn-author-ui .lrn-list-striped>li:nth-child(2n) lrn-list-view-heading-wrapper a[type="button"] {
    background: #f7f7f7;
  }
  
  .lrn .lrn-author-ui .lrn-list-striped>li:nth-child(2n) lrn-list-view-heading-wrapper a[type="button"]::before {
      border: 1px solid #f7f7f7;
  }
  
  .lrn.lrn-assess .lrn-right-region .right-wrapper {
    height: auto !important;
  }
  
  span.lrn_choiceLabel {
    font-size: 18pt !important;
  }
  
  .lrn_question p, .lrn_response_wrapper p, .lrn_font_size_normal {
    font-size: 12pt !important;
  }
  
  .lrn_contentWrapper * {
    color: #333 !important;
  }
  
  .lrn_question *, .lrn_response_wrapper * {
    font-size: 12pt !important;
    /*color: #333 !important;*/
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  /*.lrn_stimulus .lrn_stimulus_content>* {
    margin: -4px 4px 0em !important;
  }*/
  
  .lrn_stimulus .lrn_stimulus_content>* {
    margin: -5px 0 1em !important;
  }
  
  .lrn_stimulus .lrn_stimulus_content {
    line-height: 1.3em !important;
  }
  
  .set_logo {
    float: right;
    margin: 17px 20px 0 0;
  }
  
  .lrn-assess .lrn-dialog .lrn-dialog-footer {
    min-height: 50px !important;
  }
  
  .sidebar-menu .sidebar-link.active:before {
    left: 7px !important;
  }
  
  .filestyle label.btn.btn-default {
      border: 1px solid #17070747;
  }
  
  .green_dot {
    background-color: #5cb85c !important;
    right: 0px;
    position: relative;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    padding-left: 6px;
    color: white;
    font-weight: bold;
  }
  
  .dot {
      top: 1px;
      right: 0px;
      position: relative;
      width: 20px;
      background-color: #c93c3c;
      border-radius: 50%;
      display: inline-block;
      padding-left: 6px;
      color: white;
      font-weight: bold;
  }
  
  .attachments img {
      width: auto;
      height: auto;
      border-radius: 0px;
      object-fit: cover;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
      max-width: 100%;
      max-height: 100%;
  }
  
  .msg_attachements li {
      display: inline-block;
      margin-right: 4px;
      position: relative;
  }
  .msg_attachements li a.att_file_box {
      display: block;
      width: 180px;
      height: 120px;
      overflow: hidden;
      border: 1px solid #f1f1f1;
      position: relative;
      background: #fff;
  }
  .msg_type_files li {
      display: block;
      margin: 5px 0;
      position: relative;
  }
  .msg_attachements li span.file_name {
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 12px;
      background: #eee;
      padding: 5px 11px;
  }
  .msg_attachements li span.file_name:before {
      content: "";
      margin-right: 8px;
      color: #666;
      font-family: fontAwesome;
  }
  .msg_type_files li a {
      display: block;
      padding: 5px 10px;
      background: #f3f3f3;
      border: 1px solid #eee;
      text-decoration: none;
      border-radius: 2px;
      padding-left: 45px;
  }
  div.attachments {
      padding: 12px 0 0;
      margin-top: 12px;
      border-top: 1px dotted #ccc;
  }
  .msg_attachements li a.att_file_box:before {
      content: "";
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      z-index: 9;
      position: absolute;
      opacity: 0;
  }
  .msg_attachements li a.att_file_box:hover:before{
      opacity: 1;
  }
  form.msg_form {
      position: relative;
      border: 1px solid #ddd;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
  }
  form.msg_form i.attachment_icon {
      background-color: transparent;
      border-radius: 17px;
      height: 32px;
      width: 32px;
      color: #428bca;
      cursor: pointer;
      font-size: 22px;
      padding: 4px 0;
      text-align: center;
  }
  *{
      outline: none!important;
  }
  .msg_form button.btn {
      font-size: 0;
      height: 32px;
      width: 32px;
      padding: 0;
      background: transparent;
      border-color: transparent;
  }
  .msg_form button.btn,.msg_form textarea:focus,.msg_form textarea:active,.msg_form textarea{
       box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
  }
  .msg_form button.btn:before {
      content: "";
      font-family: fontAwesome;
      color: #428bca;
      font-size: 20px;
  }
  .msg_form textarea {
      height: 120px;
      resize: none;
      border: 0;
      box-shadow: none;
  }
  ul.msg_control {
      padding: 4px 3px;
      margin: 0;
      list-style: none;
      border-top: 1px solid #ddd;
      background: #f6f6f6;
  }
  ul.msg_control li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
      position: relative;
  }
  ul.msg_control li:after {
      display: block;
      content: "";
      position: absolute;
      height: 20px;
      width: 1px;
      background: #ccc;
      top: 6px;
      right: -13px;
  }
  ul.msg_control li:last-child:after {
      content: none;
  }
  
  .msg_type_files li a.file_del {
      position: absolute;
      right: 0;
      top: 2px;
      color: #444;
      font-weight: normal;
      font-size: 12px;
      background: transparent;
      border: 0;
      padding: 5px 12px;
  }
  
  .uploaded_items > img {
      height: 58px;
  }
  
  img.image_thumbnails {
      height: 18px;
      position: absolute;
      width: 32px;
      margin: 6px;
  }
  
  .user_message .sent_time {
      position: absolute;
      right: 7px;
      top: 4px;
      font-size: 13px;
      color: #969494;
  }
  .user_message {
      display: block;
      margin: 10px 0px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 10px;
      position: relative;
      margin: 10px;
      padding-left: 62px;
  }
  .user_message img.user_thumb {
      position: absolute;
      left: 10px;
      top: 10px;
  }
  
  .user_message img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      object-fit: cover;
      margin-right: 20px;
  }
  
  .chat_time {
      text-align: center;
      background: #ecebeb;
      font-size: 12px;
      padding: 5px;
  }
  
  img.user_thumb.admin_msg {
      width: 30px;
      height: 30px;
      border-radius: initial;
      object-fit: cover;
      margin-right: 20px;
  }
  
  div#report_issue_mastery_check_chosen, div#report_issue_student_chosen {
      width: 468px !important;
  }
  
  .btn-default {
      background-color: #fff !important;
      border-color: #ccc !important;
      color: #2196f3 !important;
  }
  
  .report_tabs {
      /*overflow: auto;*/
  }
  
  .mt-30 {
      margin-top: 30px;
  }
  
  .mt-50 {
      margin-top: 50px;
  }
  
  ul.nav.nav-tabs.new_nav_tab {
      /*border: none;*/
      width: 60%;
  }
  
  .nav-tabs li.active {
      /*border: 2px solid #72777A;*/
  }
  
  .nav-tabs li {
      text-align: center;
  }
  
  .lock, .pencil {
      width: 20px;
      height: 20px;
  }
  
  .box {
    float: left;
    height: 20px;
    width: 20px;
    clear: both;
    margin: 0px 10px 0px 0px;
    border-radius: 50%;
  }
  
  span.equi_head {
      margin-left: 15px;
  }
  
  .back-grey {
      background-color: #ccc;
  }
  
  .blue_box_stripes {
     float: left;
     background-color: #2196f3ad;
     border: 3px solid #2196f3ad;
     width: 20px;
     height: 20px;
     border-radius: 50%;
     background: #fff;
     clear: both;
     margin: 0px 10px 0px 0px;
  }
  
  .green_box_stripes {
     float: left;
     background-color: #0080008a;
     border: 3px solid #0080008a;
     width: 20px;
     height: 20px;
     border-radius: 50%;
     background: #fff;
     clear: both;
     margin: 0px 10px 0px 0px;
  }
  
  svg.highcharts-root {
    max-height: 285px;
    width: 433px;
  }
  
  .highcharts-tooltip {
    transform: scale(1.8);
  }
  
  .equi_perc, .ignore_perc {
    position: absolute;
    top: 88px;
    text-align: center;
    left: 184px;
    font-size: 36px;
    font-weight: bold;
    max-height: 124px;
    /*overflow: hidden;*/
  }
  
  .graph_option_color {
    font-size: 26px;
    color: #2196f3ad;
    word-wrap: break-word;
    width: 112px;
    text-align: center;
  }
  
  .ignore_perc {
    color: #2196f3ad;
  }
  
  .progress-bar {
    color: #000 !important;
    overflow: hidden;
  }
  
  .new_nav_tab.nav-tabs>li.active>a, .new_nav_tab.nav-tabs>li.active>a:hover, .new_nav_tab.nav-tabs>li.active>a:focus {
    background-color: #88888838;
    border: 1px solid #888;
    cursor: default;
    font-weight: bold;
    border-bottom: 1px solid #fff !important;
  }
  
  .new_nav_tab.nav-tabs>li>a {
    margin-right: 2px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
  }
  
  .new_nav_tab.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: #555555;
    height: 100%;
  }
  
  .new_nav_tab.nav-tabs {
    border-bottom: 1px solid #888;
  }
  
  .new_nav_tab.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }
  
  .new_nav_tab.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
  }
  
  .new_nav_tab.nav>li {
    position: relative;
    display: block;
  }
  
  .no-wrap {
    white-space: nowrap;
  }
  
  a.h-77, a.h-77.active.show {
    height: 77px !important;
  }
  
  ul.nav.nav-tabs.new_nav_tab.exploring_probability {
    font-size: 18px;
    font-weight: bold;
  }
  
  .ml-60 {
    margin-left: 60px;
  }
  
  .mt-10 {
    margin-top: 10px;
  }
  
  .pd-0 {
    padding: 0 !important;
  }
  
  .ml-20 {
    margin-left: 20px !important;
  }
  
  .ml-10 {
    margin-left: 10px !important;
  }
  
  .ml-8 {
    margin-left: 8px !important;
  }
  
  .ml-22 {
    margin-left: 20px !important;
  }
  
  .font-18 {
    font-size: 18px;
  }
  
  
  div#student_groups_ignore1, div#student_groups1, div#student_groups, div#student_groups_ignore {
    font-size: 16px;
  }
  
  .font-16, #glance, #glance1, #at_a_glance_ignore, #at_a_glance {
    font-size: 16px !important;
  }
  
  .minh-68 {
    min-height: 68px;
  }
  
  .dotted_line {
    border-bottom: 2px dotted #72777a;
  }
  
  .dotted_top_line {
    border-top: 2px dotted #72777a;
  }
  
  .pdb-20 {
    padding-bottom: 20px;
  }
  
  .pl-0 {
    padding-left: 0px;
  }
  
  @media only screen and (max-width: 2560px) and (min-width:2500px) {
    .graph_option_color {
      font-size: 20px;
    }
  
    svg.highcharts-root {
      max-height: 284px;
      width: 600px;
    }
  
    .item_description {
      top: 40px !important;
      width: auto !important;
      left: 420px !important;
    }
  
    .equi_perc, .ignore_perc {
      top: 76px !important;
      left: 262px !important;
    }
  }
  
  @media only screen and (max-width: 2266px) and (min-width:2200px) {
    svg.highcharts-root {
      width: 733px;
    }
  
    .equi_perc, .ignore_perc {
      left: 325px;
    }
  }
  
  @media only screen and (max-width: 1660px) and (min-width:1500px) {
    .graph_option_color {
      font-size: 25px;
      /*width: 85px;*/
    }
  
    svg.highcharts-root {
      width: 312px !important;
      min-width: 500px !important;
    }
  
    .equi_perc, .ignore_perc {
      top: 70px;
      left: 215px;
      max-height:  unset;
    }
  
  }
  
  .bw2p {
    border-right: 3px solid #666 !important;
  }
  
  .bb3p {
    border-bottom: 3px solid #666 !important;
  }
  
  .item_description {
    top: 290px;
    width: auto;
    font-size: 16px;
    overflow: auto;
  }
  
  .mt-15 {
    margin-top: 15px;
  }
  
  .pdb-50 {
    padding-bottom: 50px;
  }
  
  .pdt-50 {
    padding-top: 50px;
  }
  
  .progress-bar.other {
    background-color: #ccc;
  }
  
  footer.bdT > span {
    font-size: 12px;
  }

  .table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 800px;
}

.table-scroll table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.table-wrap {
    position: relative;
}

.table-scroll thead tr:nth-child(1) th {
    position: sticky;
    top: 0;
    background: #fff;
}

.table-scroll thead tr:nth-child(2) th {
    position: sticky;
    top: 42px;
    background: #fff;
}

.table-scroll thead tr:nth-child(3) th {
    position: sticky;
    top: 100px;
    background: #fff;
}

.table-scroll thead tr:nth-child(4) th {
    position: sticky;
    top: 142px;
    background: #fff;
}

ul.nav.nav-tabs.new_nav_tab {
    width: 100%;
    height: 100%;
}

div.tooltip-inner {
    max-width: 230px;
}

div#glance,
#equiprobability,
#equiprobability .tab-content.report_tabs,
#ignore,
#ignore .tab-content.report_tabs {
    overflow: hidden;
}

.need {
    background: #2196f3ad;
}

.understand {
    background-color: #0080008a;
}

.progress {
    height: 30px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    background-color: #ccc;
}

.learnosity-item .lrn_question {
    padding-left: 24px;
}

.tooltip {
    font-size: 16px;
}

.subnavtabs>li>a {
    border-color: #ccc;
    white-space: normal;
}

.bound-student-list {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: #fafafa !important;
    margin: 5px;
    margin-left: 15px;
}

.bound-student-list.incomplete {
    background-color: #eee !important;
}

.circle-note {
    margin: 10px;
    margin-left: none;
    margin-bottom: 20px;
    display: inline-block !important;
    font-size: 14px;
}

.grayed {
    background-color: #eee;
}

.ml-20 {
    margin-left: 20px !important;
}

.vert-head {
    display: table-cell !important;
    vertical-align: middle !important;
}

.class_results {
    color: #000 !important;
}

.chosen-container-single .chosen-single {
    height: 35px !important;
    background: none !important;
}


.container-fluid {
    overflow-x: scroll !important;
}

.lrn-assess .lrn-dialog .lrn-dialog-body {
    margin: 5px 0 !important;
}

.lrn .lrn-author-ui .lrn-list-view-heading-wrapper a[type="button"] {
    background: #fff;
    position: relative;
}

.lrn .lrn-author-ui .lrn-list-view-heading-wrapper a[type="button"]::before {
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: block;
    position: absolute;
}

.lrn .lrn-author-ui .lrn-list-striped>li:nth-child(2n) lrn-list-view-heading-wrapper a[type="button"] {
    background: #f7f7f7;
}

.lrn .lrn-author-ui .lrn-list-striped>li:nth-child(2n) lrn-list-view-heading-wrapper a[type="button"]::before {
    border: 1px solid #f7f7f7;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper {
    height: auto !important;
}

span.lrn_choiceLabel {
    font-size: 18pt !important;
}

.lrn_question p,
.lrn_response_wrapper p,
.lrn_font_size_normal {
    font-size: 12pt !important;
}

.lrn_contentWrapper * {
    color: #333 !important;
}

.lrn_question *,
.lrn_response_wrapper * {
    font-size: 12pt !important;
    /*color: #333 !important;*/
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*.lrn_stimulus .lrn_stimulus_content>* {
margin: -4px 4px 0em !important;
}*/

.lrn_stimulus .lrn_stimulus_content>* {
    margin: -5px 0 1em !important;
}

.lrn_stimulus .lrn_stimulus_content {
    line-height: 1.3em !important;
}

.set_logo {
    float: right;
    margin: 17px 20px 0 0;
}

.lrn-assess .lrn-dialog .lrn-dialog-footer {
    min-height: 50px !important;
}

.sidebar-menu .sidebar-link.active:before {
    left: 7px !important;
}

.filestyle label.btn.btn-default {
    border: 1px solid #17070747;
}

.green_dot {
    background-color: #5cb85c !important;
    right: 0px;
    position: relative;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    padding-left: 6px;
    color: white;
    font-weight: bold;
}

.dot {
    top: 1px;
    right: 0px;
    position: relative;
    width: 20px;
    background-color: #c93c3c;
    border-radius: 50%;
    display: inline-block;
    padding-left: 6px;
    color: white;
    font-weight: bold;
}

.attachments img {
    width: auto;
    height: auto;
    border-radius: 0px;
    object-fit: cover;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
}

.msg_attachements li {
    display: inline-block;
    margin-right: 4px;
    position: relative;
}

.msg_attachements li a.att_file_box {
    display: block;
    width: 180px;
    height: 120px;
    overflow: hidden;
    border: 1px solid #f1f1f1;
    position: relative;
    background: #fff;
}

.msg_type_files li {
    display: block;
    margin: 5px 0;
    position: relative;
}

.msg_attachements li span.file_name {
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 12px;
    background: #eee;
    padding: 5px 11px;
}

.msg_attachements li span.file_name:before {
    content: "";
    margin-right: 8px;
    color: #666;
    font-family: fontAwesome;
}

.msg_type_files li a {
    display: block;
    padding: 5px 10px;
    background: #f3f3f3;
    border: 1px solid #eee;
    text-decoration: none;
    border-radius: 2px;
    padding-left: 45px;
}

div.attachments {
    padding: 12px 0 0;
    margin-top: 12px;
    border-top: 1px dotted #ccc;
}

.msg_attachements li a.att_file_box:before {
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
    position: absolute;
    opacity: 0;
}

.msg_attachements li a.att_file_box:hover:before {
    opacity: 1;
}

form.msg_form {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: -1 1px 1px rgba(0, 0, 0, 0.05);
}

form.msg_form i.attachment_icon {
    background-color: transparent;
    border-radius: 17px;
    height: 32px;
    width: 32px;
    color: #428bca;
    cursor: pointer;
    font-size: 22px;
    padding: 4px 0;
    text-align: center;
}

* {
    outline: none !important;
}

.msg_form button.btn {
    font-size: 0;
    height: 32px;
    width: 32px;
    padding: 0;
    background: transparent;
    border-color: transparent;
}

.msg_form button.btn,
.msg_form textarea:focus,
.msg_form textarea:active,
.msg_form textarea {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
}

.msg_form button.btn:before {
    content: "";
    font-family: fontAwesome;
    color: #428bca;
    font-size: 20px;
}

.msg_form textarea {
    height: 120px;
    resize: none;
    border: 0;
    box-shadow: none;
}

ul.msg_control {
    padding: 4px 3px;
    margin: 0;
    list-style: none;
    border-top: 1px solid #ddd;
    background: #f6f6f6;
}

ul.msg_control li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    position: relative;
}

ul.msg_control li:after {
    display: block;
    content: "";
    position: absolute;
    height: 20px;
    width: 1px;
    background: #ccc;
    top: 6px;
    right: -13px;
}

ul.msg_control li:last-child:after {
    content: none;
}

.msg_type_files li a.file_del {
    position: absolute;
    right: 0;
    top: 2px;
    color: #444;
    font-weight: normal;
    font-size: 12px;
    background: transparent;
    border: 0;
    padding: 5px 12px;
}

.uploaded_items>img {
    height: 58px;
}

img.image_thumbnails {
    height: 18px;
    position: absolute;
    width: 32px;
    margin: 6px;
}

.user_message .sent_time {
    position: absolute;
    right: 7px;
    top: 4px;
    font-size: 13px;
    color: #969494;
}

.user_message {
    display: block;
    margin: 10px 0px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
    margin: 10px;
    padding-left: 62px;
}

.user_message img.user_thumb {
    position: absolute;
    left: 10px;
    top: 10px;
}

.user_message img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    margin-right: 20px;
}

.chat_time {
    text-align: center;
    background: #ecebeb;
    font-size: 12px;
    padding: 5px;
}

img.user_thumb.admin_msg {
    width: 30px;
    height: 30px;
    border-radius: initial;
    object-fit: cover;
    margin-right: 20px;
}

div#report_issue_mastery_check_chosen,
div#report_issue_student_chosen {
    width: 468px !important;
}

.btn-default {
    background-color: #fff !important;
    border-color: #ccc !important;
    color: #2196f3 !important;
}

.report_tabs {
    /*overflow: auto;*/
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

/* ul.nav.nav-tabs.new_nav_tab {
    /*border: none;*/
    /* width: 60%;
} */

.nav-tabs li.active {
    /*border: 2px solid #72777A;*/
}

.nav-tabs li {
    text-align: center;
}

.lock,
.pencil {
    width: 20px;
    height: 20px;
}

.box {
    float: left;
    height: 20px;
    width: 20px;
    clear: both;
    margin: 0px 10px 0px 0px;
    border-radius: 50%;
}

span.equi_head {
    margin-left: 15px;
}

.back-grey {
    background-color: #ccc;
}

.blue_box_stripes {
    float: left;
    background-color: #2196f3ad;
    border: 3px solid #2196f3ad;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    clear: both;
    margin: 0px 10px 0px 0px;
}

.green_box_stripes {
    float: left;
    background-color: #0080008a;
    border: 3px solid #0080008a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    clear: both;
    margin: 0px 10px 0px 0px;
}

svg.highcharts-root {
    max-height: 285px;
    width: 433px;
}

.highcharts-tooltip {
    transform: scale(1.8);
}

.equi_perc,
.ignore_perc {
    position: absolute;
    top: 88px;
    text-align: center;
    left: 184px;
    font-size: 36px;
    font-weight: bold;
    max-height: 124px;
    /*overflow: hidden;*/
}

.graph_option_color {
    font-size: 26px;
    color: #2196f3ad;
    word-wrap: break-word;
    width: 112px;
    text-align: center;
}

.ignore_perc {
    color: #2196f3ad;
}

.progress-bar {
    color: #000 !important;
    overflow: hidden;
}

.new_nav_tab.nav-tabs>li.active>a,
.new_nav_tab.nav-tabs>li.active>a:hover,
.new_nav_tab.nav-tabs>li.active>a:focus {
    background-color: #88888838;
    border: 1px solid #888;
    cursor: default;
    font-weight: bold;
    border-bottom: 1px solid #fff !important;
}

.new_nav_tab.nav-tabs>li>a {
    margin-right: 2px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.new_nav_tab.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: #555555;
    height: 100%;
}

.new_nav_tab.nav-tabs {
    border-bottom: 1px solid #888;
}

.new_nav_tab.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.new_nav_tab.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.new_nav_tab.nav>li {
    position: relative;
    display: block;
}

.no-wrap {
    white-space: nowrap;
}

a.h-77,
a.h-77.active.show {
    height: 77px !important;
}

ul.nav.nav-tabs.new_nav_tab.exploring_probability {
    font-size: 18px;
    font-weight: bold;
}

.ml-60 {
    margin-left: 60px;
}

.mt-10 {
    margin-top: 10px;
}

.pd-0 {
    padding: 0 !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-22 {
    margin-left: 20px !important;
}

.font-18 {
    font-size: 18px;
}


div#student_groups_ignore1,
div#student_groups1,
div#student_groups,
div#student_groups_ignore {
    font-size: 16px;
}

.font-16,
#glance,
#glance1,
#at_a_glance_ignore,
#at_a_glance {
    font-size: 16px !important;
}

.minh-68 {
    min-height: 68px;
}

.dotted_line {
    border-bottom: 2px dotted #72777a;
}

.dotted_top_line {
    border-top: 2px dotted #72777a;
}

.pdb-20 {
    padding-bottom: 20px;
}

.pl-0 {
    padding-left: 0px;
}

@media only screen and (max-width: 2560px) and (min-width:2500px) {
    .graph_option_color {
        font-size: 20px;
    }

    svg.highcharts-root {
        max-height: 284px;
        width: 600px;
    }

    .item_description {
        top: 40px !important;
        width: auto !important;
        left: 420px !important;
    }

    .equi_perc,
    .ignore_perc {
        top: 76px !important;
        left: 262px !important;
    }
}

@media only screen and (max-width: 2266px) and (min-width:2200px) {
    svg.highcharts-root {
        width: 733px;
    }

    .equi_perc,
    .ignore_perc {
        left: 325px;
    }
}

@media only screen and (max-width: 1660px) and (min-width:1500px) {
    .graph_option_color {
        font-size: 25px;
        /*width: 85px;*/
    }

    svg.highcharts-root {
        width: 312px !important;
        min-width: 500px !important;
    }

    .equi_perc,
    .ignore_perc {
        top: 70px;
        left: 215px;
        max-height: unset;
    }

}

.bw2p {
    border-right: 3px solid #666 !important;
}

.bb3p {
    border-bottom: 3px solid #666 !important;
}

.item_description {
    top: 290px;
    width: auto;
    font-size: 16px;
    overflow: auto;
}

.mt-15 {
    margin-top: 15px;
}

.pdb-50 {
    padding-bottom: 50px;
}

.pdt-50 {
    padding-top: 50px;
}

.progress-bar.other {
    background-color: #ccc;
}

footer.bdT>span {
    font-size: 12px;
}
